const buildUploadParams = (type, params) => {
  switch (type) {
    case 'historic':
      return [
        {
          params: {
            station: params.station,
            unit: params.unit,
            cycle: params.cycle,
            owner: params.owner,
            notes: params.notes,
            point_coverage: params.point_coverage,
            date_range: params.date_range
          },
          withCredentials: true
        },
        {
          headers: {
            'x-amz-meta-station': params.station,
            'x-amz-meta-unit': params.unit,
            'x-amz-meta-cycle': params.cycle,
            'x-amz-meta-owner': params.owner,
            'x-amz-meta-notes': params.notes,
            'x-amz-meta-point_coverage': params.point_coverage,
            'x-amz-meta-date_range': params.date_range
          }
        }
      ]
    case 'prediction':
      return [
        {
          params: {
            station: params.station,
            unit: params.unit,
            cycle: params.cycle,
            batch_size: params.batchSize,
            scenario: params.scenario,
            name: params.name,
            owner: params.owner,
            notes: params.notes
          },
          withCredentials: true
        },
        {
          headers: {
            'x-amz-meta-station': params.station,
            'x-amz-meta-unit': params.unit,
            'x-amz-meta-cycle': params.cycle,
            'x-amz-meta-scenario': params.scenario,
            'x-amz-meta-name': params.name,
            'x-amz-meta-owner': params.owner,
            'x-amz-meta-batch_size': params.batchSize,
            'x-amz-meta-notes': params.notes
          }
        }
      ]
    case 'reports':
      return {
        params: {}
      }
    default:
      return null
  }
}

export default buildUploadParams
