import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/NavBar'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { SiAtom } from 'react-icons/si'
import { BsDropletFill } from 'react-icons/bs'
import { RiLightbulbFlashFill } from 'react-icons/ri'
import { HistoryIcon, FileIcon, SignOutIcon, LightBulbIcon } from '@primer/octicons-react'
import { useCookies } from 'react-cookie'

import 'bootstrap/dist/css/bootstrap.css'

const Header = props => {
  let prefix = '/'
  if (typeof window !== 'undefined') {
    const subSite = window.location.pathname.split('/')[1]
    prefix = (
      (['mco', 'eigen'].includes(subSite))
        ? '/' + subSite
        : ''
    )
  }
  const [cookies, setCookie, removeCookie] = useCookies()
  const logout = () => {
    document.cookie = (
      'mco-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
      ' domain=.demo.bwnuclear.ai; path=/;'
    )
    document.cookie = (
      'mco-id-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
      ' domain=.demo.bwnuclear.ai; path=/;'
    )
    window.location.reload()
  }
  return (
    <Container fluid='xs'>
      <Row>
        <Col>
          <Navbar
            fixed='top'
            className='mb-3'
            style={{
              backgroundColor: '#f3f4f7',
              backgroundImage: 'linear-gradient(315deg, #f3f4f7 0%, #caccd1 74%)',
              boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 5px 5px rgba(0,0,0,0.23)',
              zIndex: '100',
              backdropFilter: 'blur(4rem)'
            }}
          >
            <Container fluid>
              <Navbar.Brand>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='primary'
                    id='dd-action'
                    style={{
                    // backgroundColor: '#f3f4f7',
                    // backgroundImage: 'linear-gradient(315deg, #f3f4f7 0%, #caccd1 74%)',
                      borderRadius: '25px'
                    }}
                    bg='primary'
                  >
                    <SiAtom size={32} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled>
                      <Dropdown.Header>
                        <SiAtom size={16} />
                        &nbsp;
                        BW Nuclear
                      </Dropdown.Header>
                      {/* <Link
                        to='/'
                        style={{ textDecoration: 'none' }}
                      /> */}
                      <Dropdown.Divider />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to='/mco/'
                        style={{
                          textDecoration: 'none',
                          padding: '10px',
                          display: 'inline-block',
                          lineHeight: '20px'
                        }}
                      >
                        <BsDropletFill size={16} />
                        &nbsp;
                        MCO.ai
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to='/eigen/'
                        style={{
                          textDecoration: 'none',
                          padding: '10px',
                          display: 'inline-block',
                          lineHeight: '20px'
                        }}
                      >
                        <RiLightbulbFlashFill size={16} />
                        &nbsp;
                        eigenvalue.ai
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={logout}
                      style={{
                        backgroundRepeat: 'no-repeat',
                        border: 'none',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        outline: 'none'
                      }}
                    >
                      {/* <Dropdown.Divider /> */}
                      <Link
                        to='/'
                        style={{
                          textDecoration: 'none',
                          padding: '10px',
                          display: 'inline-block',
                          lineHeight: '20px'
                        }}
                      >
                        <SignOutIcon size={16} />
                        &nbsp;
                        Logout
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Navbar.Brand>

              <Nav>
                <Nav.Item>
                  <Link
                    to={prefix + '/history'}
                    style={{
                      textDecoration: 'none',
                      padding: '10px',
                      display: 'inline-block',
                      lineHeight: '20px'
                    }}
                  >
                    <HistoryIcon size={24} />
                    &nbsp;
                    Historical Data
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to={prefix + '/predictions'}
                    style={{
                      textDecoration: 'none',
                      padding: '10px',
                      display: 'inline-block',
                      lineHeight: '20px'
                    }}
                  >
                    <LightBulbIcon size={24} />
                    &nbsp;
                    Predictions
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link
                    to={prefix + '/reports'}
                    style={{
                      textDecoration: 'none',
                      padding: '10px',
                      display: 'inline-block',
                      lineHeight: '20px'
                    }}
                  >
                    <FileIcon size={24} />
                    &nbsp;
                    Reports
                  </Link>
                </Nav.Item>

              </Nav>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
