import React, { useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'

const WarningTextAlert = (props) => {
  const handleAlertClose = props.remove
  return (
    <Alert
      dismissible
      onClose={handleAlertClose}
      variant='warning'
    >
      <p style={{
        'whiteSpace': 'pre-line',
        'marginBottom': '0px'
      }}
      >
        {props.text}
      </p>
    </Alert>
  )
}

export default WarningTextAlert
