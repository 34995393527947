const appInfo = {
  stations: {
    vino: {
      name: 'Vino',
      units: {
        1: {
          eig_model: 'availible',
          limit: 0.1,
          operatingCycle: 21
        }
      },
    },
    lux: {
      name: 'Lux',
      units: {
        2: {
          eig_model_expected: 'January 1, 2023',
          operatingCycle: 21
        },
        3: {
          eig_model_expected: 'January 1, 2023',
          operatingCycle: 21
        }
      }
    },
    alea: {
      name: 'Alea',
      units: {
        1: {
          limit: 0.1,
          eig_model: 'availible',
          mco_model: 'availible',
          operatingCycle: 25
        }
      },
    },
    timendi: {
      name: 'Timendi',
      units: {
        1: {
          limit: 0.15,
          eig_model: 'availible',
          operatingCycle: 19
        },
        2: {
          limit: 0.15,
          eig_model: 'availible',
          operatingCycle: 19
        }
      }
    }
  }  
}

export default appInfo