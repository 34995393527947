import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const default_ = [
  {
    key: 'MCO',
    axis: 1,
    dominant: true,
    color: 'steelblue',
    rgbaColor: { r: '70', g: '130', b: '180', a: '1' },
    displayColorPicker: false,
    hexColor: '#4682b4'
  },
  {
    key: 'TFW',
    axis: 2,
    dominant: true,
    color: 'green',
    rgbaColor: { r: '0', g: '128', b: '0', a: '1' },
    displayColorPicker: false,
    hexColor: '#008000'
  },
  {
    key: 'WCT',
    axis: 3,
    dominant: true,
    color: 'brown',
    rgbaColor: { r: '165', g: '42', b: '42', a: '1' },
    displayColorPicker: false,
    hexColor: '#964B00'
  },
  {
    key: 'RP',
    axis: 4,
    dominant: true,
    color: 'goldenrod',
    rgbaColor: { r: '218', g: '165', b: '32', a: '1' },
    displayColorPicker: false,
    hexColor: '#daa520'
  }
]

const initialState = {
  alerts: [],
  graph: default_
}

function reducer (state, action) {
  switch (action.type) {
    case 'addAlert': {
      if (!action.toAdd.id) {
        action.toAdd.id = uuidv4()
      }
      return { alerts: [...state.alerts, action.toAdd].sort(x => x.id) }
    }
    case 'updateAlert': {
      const candidates = state.alerts.filter(x => x.id === action.id)
      if (candidates.length !== 1) {
        console.log('no candidates')
        return {}
      }
      const itemToUpdate = candidates[0]
      Object.entries(action.updates).forEach(x => {
        itemToUpdate[x[0]] = x[1]
      })
      return {
        alerts: state.alerts
          .filter(x => x.id !== action.id)
          .concat(itemToUpdate)
          .sort(x => x.id)
      }
    }
    case 'removeAlert': {
      return { 
        alerts: state.alerts.filter(x => x.id !== action.id).sort(x => x.id)
      }
    }
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
