import React from 'react'
import Alert from 'react-bootstrap/Alert'
import ProgressBar from 'react-bootstrap/ProgressBar'

const UploadProgressAlert = (props) => {
  const handleAlertClose = props.remove
  return (
    <Alert>
      Uploading File {props.filename}...
      <ProgressBar now={props.progress} />
    </Alert>
  )
}

export default UploadProgressAlert
