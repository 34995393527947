import React from 'react'
import Alert from 'react-bootstrap/Alert'

const DownloadLinkAlert = (props) => {
  const handleAlertClose = props.remove
  return (
    <Alert dismissible onClose={handleAlertClose}>
      Download is availible&nbsp;
      <Alert.Link href={props.url}>
        here
      </Alert.Link>
      .
    </Alert>
  )
}

export default DownloadLinkAlert
