import axios from 'axios'
import gql from 'graphql-tag'
import { print } from 'graphql'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Header from './header'
import Hero from './hero'
import Footer from './footer'
import AlertTray from './alertTray'
import upload from '../tools/aws/upload'
import { v4 as uuidv4 } from 'uuid'
import GlobalContextProvider from '../context/global_context_provider'
import GraphContextProvider from '../context/graph_context_provider'
import appInfo from '../static/app_info'
import 'bootstrap/dist/css/bootstrap.css'
import { Title, Link as MetaLink, Meta } from "react-head"
import { HeadProvider } from 'react-head'

const Layout = ({ children }) => {
  let prefix = '/'
  let subSite = ''
  if (typeof window !== 'undefined') {
    subSite = window.location.pathname.split('/')[1]
    prefix = (
      (['mco', 'eigen'].includes(subSite))
        ? '/' + subSite
        : ''
    )
  }
  const siteTitle = (
    (subSite)
      ? {
          mco: 'mco',
          eigen: 'eigenvalue',
          tip: 'tip trace'
        }[subSite]
      : 'landing'
  )
  const [show, setShow] = useState(false)
  const [creds, setCreds] = useState(true)
  const [data, setData] = useState('')
  const [fetching, setFetch] = useState(false)
  const [personalView, setPersonalView] = useState(false)
  const togglePersonalView = () => setPersonalView(!personalView)
  const [alerts, setAlerts] = useState([])
  const alertRef = useRef(alerts)
  alertRef.current = alerts
  const addAlert = (newAlert) => {
    newAlert.id = uuidv4()
    setAlerts(oldArray => [...oldArray, newAlert])
    return newAlert.id
  }
  const updateAlert = (id, updates) => {
    const candidates = alertRef.current.filter(x => x.id === id)
    if (candidates !== 1) {
      return null
    }
    const itemToUpdate = candidates[0]
    Object.entries(updates).forEach((k, v) => {
      itemToUpdate[k] = v
    })
    setAlerts(
      alertRef
        .current
        .filter(x => x.id !== id)
        .concat(itemToUpdate)
    )
  }
  const removeAlert = (id) => {
    console.log('attempting remove of alert')
    console.log(alertRef.current)
    setAlerts(alertRef.current.filter(x => x.id !== id))
  }
  const startFetch = () => setFetch(true)
  const endFetch = () => setFetch(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const fetchData = async () => {
    if (fetching) {
      return false
    }
    startFetch()
    console.log('fetching data')
    console.log(subSite)
    const graphqlData = await axios.get(
      'https://api.demo.bwnuclear.ai/alldata/' + subSite,
      { withCredentials: true }
    )
    console.log('data call:')
    console.log(graphqlData)
    const profileData = await axios.get(
      'https://api.demo.bwnuclear.ai/profile',
      { withCredentials: true }
    )
    console.log('profile call:')
    console.log(profileData)
    const clientId = '2h29amcnc9m9e022gn5b3v7d7k'
    if (!graphqlData.data.app_info || !profileData.data.result) {
      console.log('call didnt work, redirect')
      window.location.href = (
        'https://demo-auth.auth-fips.us-gov-west-1.amazoncognito.com/login' + 
        '?client_id=' + clientId +
        '&response_type=token' + 
        '&scope=openid+profile' + 
        '&redirect_uri=https://api.demo.bwnuclear.ai/setcookie/' + subSite
      )
    } else {
      setData({
        hdata: graphqlData.data.historic,
        predictions: graphqlData.data.predictions,
        reports: graphqlData.data.reports,
        app_info: graphqlData.data.app_info,
        profile: profileData.data.result
      })
    }
    endFetch()
  }
  useEffect(() => {
    if (creds === null) {
      handleShow()
    }
  })
  useEffect(() => {
    if (
      data === '' 
      && fetching === false 
      && subSite !== ''
      && subSite !== 'tip'
    ) {
      console.log(subSite)
      console.log('fetching because no data and not fetching')
      fetchData()
    }
  }, [data])
  const uploadFile = async (type, file, params) => {
    addAlert('text', { text: 'Starting upload...' })
    await upload(type, file, params)
    addAlert('text', { text: 'Upload completed' })
  }
  const Child = React.Children.only(children)
  const propsToPass = {
    data: data,
    appInfo: appInfo,
    addAlert: addAlert,
    updateAlert: updateAlert,
    uploadFile: uploadFile,
    fetch: fetchData,
    personalView: personalView,
    togglePersonalView: togglePersonalView
  }
  const clone = React.cloneElement(Child, propsToPass)
  return (
      <GlobalContextProvider>
        <head>
          <title>{siteTitle}</title>
        </head>
        <Header />
        <Hero site={subSite} />
        <AlertTray
          alerts={alerts}
          remove={removeAlert}
        />
        <div>
          <main>
            {clone}
          </main>
          <Footer />
        </div>
      </GlobalContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
