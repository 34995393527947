import React from 'react'
import * as d3 from 'd3'
const _ = require('lodash')

export const GraphStateContext = React.createContext()
export const GraphDispatchContext = React.createContext()

const m = { t: 10, r: 270, b: 50, l: 60 }
const w = 1000
const h = 465

const reshape = (array, n) => {
    if (array.length === 0)
        return [];
    return [_.take(array, n)].concat(reshape(_.rest(array, n), n));
}

const default_ = [
  {
    key: 'MCO',
    axis: 1,
    dominant: true,
    color: 'steelblue',
    rgbaColor: { r: '70', g: '130', b: '180', a: '1' },
    displayColorPicker: false,
    hexColor: '#4682b4'
  },
  {
    key: 'TFW',
    axis: 2,
    dominant: true,
    color: 'green',
    rgbaColor: { r: '0', g: '128', b: '0', a: '1' },
    displayColorPicker: false,
    hexColor: '#008000'
  },
  {
    key: 'WCT',
    axis: 3,
    dominant: true,
    color: 'brown',
    rgbaColor: { r: '165', g: '42', b: '42', a: '1' },
    displayColorPicker: false,
    hexColor: '#964B00'
  },
  {
    key: 'RP',
    axis: 4,
    dominant: true,
    color: 'goldenrod',
    rgbaColor: { r: '218', g: '165', b: '32', a: '1' },
    displayColorPicker: false,
    hexColor: '#daa520'
  }
]

const initialState = {
  lines: default_,
  renderLines: default_
}

function reducer (state, action) {
  const i = action.index
  console.log('action', action.type, i, action, state)
  switch (action.type) {
    case 'addLine': {
      if (state.lines.length > 7) {
        break
      }
      const dIndex = (
        (state.lines.length < default_.length) 
          ? state.lines.length
          : 0
      )
      state.lines.push({...default_[dIndex]})
      break
    }
    case 'toggleColorPicker': {
      state.lines[i].displayColorPicker = !state.lines[i].displayColorPicker
      break
    }
    case 'toggleDominant': {
      state.lines[i].dominant = !state.lines[i].dominant
      break
    }
    case 'updateAxis': {
      state.lines[i].axis = action.value
      break
    }
    case 'updateColorChoice': {
      state.lines[i].hexColor = action.color.hex
      state.lines[i].rgbaColor = action.color.rgb
      state.lines[i].displayColorPicker = false
      break
    }
    case 'updateCoordinate': {
      state.lines[i][action.axis] = action.value
      break
    }
    case 'updateKey': {
      state.lines[i].key = action.value
      break
    }
    case 'removeLine': {
      if (state.lines.length > 1) {
        state.lines.pop()
      }
      break
    }
    case 'loadHdf': {
      state.hdf = action.hdf
      state.fileState = { keys: {} }
      let exposureMax = 0
      state.hdf.keys.forEach(pk => {
        const point = state.hdf.get(pk)
        point.keys.forEach(vk => {
          if (!(vk in state.fileState.keys)) {
            state.fileState.keys[vk] = { dim: point.get(vk).shape.length }
          }
          exposureMax = Math.max(exposureMax, point.get('CYEXP').value)
        })
      })
      console.log(state.fileState)
      const xscale = d3.scaleLinear()
        .domain([0, exposureMax])
        .range([m.l + 50, w - m.r - 50])
      state.xscale = xscale.copy()
      state.xreset = xscale.copy()
      let oldRods = null
      let newRods = null
      const thisData = []
      state.hdf.keys.forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('CYEXP').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              newPt[l.key] = parseFloat(pt.get(l.key).value[l.z])
              break
            }
            case 2: {
              const width = pt.get(l.key).shape[0]
              newPt[l.key] = parseFloat(pt.get(l.key).value[width * l.y + l.x])
              break
            }
          }
        }) 
        newRods = pt.get('NOTCH').value
        if (!_.isEqual(oldRods, newRods)) { newPt.rods = newRods }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      state.scales = []
      for (const i of [1, 2, 3, 4]) {
        console.log('setting scale for axis ' + i.toString())
        const axisLines = state.lines.filter(l => l.axis === i)
        const domFilter = axisLines.filter(l => l.dominant)
        console.log('dom:')
        if (domFilter.length > 1) {
          console.log('too many dominant lines!')
        } else if (domFilter.length === 1) {
          console.log('dominant line')
          const line = domFilter[0]
          state.scales[i] = d3.scaleLinear()
            .domain([0, d3.max(state.data, d => parseFloat(d[line.key]))])
            .range([h - m.b, m.t])
        } else {
          console.log('no dominant, taking overall max')
          const maxes = axisLines
            .map(l => d3.max(state.data, d => parseFloat(d[l.key])))
          const max = Math.max(...maxes)
          state.scales[i] = d3.scaleLinear()
            .domain([0, max])
            .range([h - m.b, m.t])
        }
      }
      state.renderLines = JSON.parse(JSON.stringify(state.lines))
      break
    }
    case 'loadData': {
      let oldRods = null
      let newRods = null
      const thisData = []
      state.hdf.keys.forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('CYEXP').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              newPt[l.key] = parseFloat(pt.get(l.key).value[l.z])
              break
            }
            case 2: {
              const width = pt.get(l.key).shape[0]
              console.log('width, x, y', width, l.x, l.y)
              console.log('index', (width * l.y + l.x))
              console.log('string', pt.get(l.key).value[width * l.y + l.x])
              newPt[l.key] = parseFloat(pt.get(l.key).value[width * l.y + l.x])
              break
            }
          }
        }) 
        newRods = pt.get('NOTCH').value
        if (!_.isEqual(oldRods, newRods)) { newPt.rods = newRods }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      break
    }
    case 'resetZoom': {
      if (!state.xreset) {
        break
      }
      state.xscale = state.xreset.copy()
      break
    }
    case 'updateZoom': {
      if (!state.xscale) {
        break
      }
      state.xscale = d3.scaleLinear().domain([
          state.xscale.invert(action.extent[0]),
          state.xscale.invert(action.extent[1])
      ]).range([m.l + 50, w - m.r - 50])
      break
    }
    case 'submit': {
      let oldRods = null
      let newRods = null
      const thisData = []
      state.hdf.keys.forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('CYEXP').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              const index = parseInt(l.y)
              newPt[l.key] = parseFloat(pt.get(l.key).value[index])
              break
            }
            case 2: { 
              const width = pt.get(l.key).shape[0]
              const index = width * parseInt(l.y) + parseInt(l.x)
              newPt[l.key] = parseFloat(pt.get(l.key).value[index])
              console.log('width, x, y', width, l.x, l.y)
              console.log('index', index)
              console.log('string', pt.get(l.key).value[index])
              console.log(
                'parsed',
                parseFloat(pt.get(l.key).value[index])
              )
              break
            }
            case 3: {
              const value = reshape(pt.get(l.key).value)[l.z][l.y][l.x]
              const parsed = parseFloat(value)
              console.log('string', value)
              console.log('value', parsed)
              newPt[l.key] = parsed
              break
            }
          }
        }) 
        newRods = pt.get('NOTCH').value
        if (!_.isEqual(oldRods, newRods)) { newPt.rods = newRods }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      state.scales = []
      for (const i of [1, 2, 3, 4]) {
        console.log('setting scale for axis ' + i.toString())
        console.log('lines', state.lines)
        const axisLines = state.lines.filter(l => l.axis === i)
        console.log('axisLines', axisLines)
        const domFilter = axisLines.filter(l => l.dominant)
        console.log('domFilter', domFilter)
        console.log('dom:')
        if (domFilter.length > 1) {
          console.log('too many dominant lines!')
        } else if (domFilter.length === 1) {
          console.log('dominant line')
          const line = domFilter[0]
          state.scales[i] = d3.scaleLinear()
            .domain([0, d3.max(state.data, d => parseFloat(d[line.key]))])
            .range([h - m.b, m.t])
        } else {
          console.log('no dominant, taking overall max')
          const maxes = axisLines
            .map(l => d3.max(state.data, d => parseFloat(d[l.key])))
          console.log('maxes', maxes)
          const max = Math.max(...maxes)
          state.scales[i] = d3.scaleLinear()
            .domain([0, max])
            .range([h - m.b, m.t])
        }
      }
      state.renderLines = JSON.parse(JSON.stringify(state.lines))
      break
    } 
  }
  console.log(state)
  return {...state}
}

const GraphContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GraphStateContext.Provider value={state}>
      <GraphDispatchContext.Provider value={dispatch}>
        {children}
      </GraphDispatchContext.Provider>
    </GraphStateContext.Provider>
  )
}

export default GraphContextProvider