import React, { useEffect, useRef } from 'react'
import Alert from 'react-bootstrap/Alert'

const PlainTextAlert = (props) => {
  const handleAlertClose = props.remove
  useEffect(() => {
    console.log('timeout created!')
    const timer = setTimeout(() => {
      console.log('triggered delete!')
      props.remove()
    }, 10000)
    return () => {
    }
  }, [])
  return (
    <Alert dismissible onClose={handleAlertClose}>
      {props.text}
    </Alert>
  )
}

export default PlainTextAlert
