import * as React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../images/plant-2560.png'
import 'bootstrap/dist/css/bootstrap.css'

const Hero = props => {
  let site = ''
  if (typeof window !== 'undefined') {
    site = window.location.pathname.split('/')[1]
  }
  const siteString = {
    'mco': 'MCO',
    'eigen': 'Eigenvalue',
    '': ''
  }[site]
  const title = 'BW Nuclear | ' + siteString
  return (
    <>
      <Container
        style={{
          'margin-top':'70px',
          'margin-bottom': '35px'
        }}
        fluid
      >
        <Row
          className='justify-content-center align-items-center'
          fluid
          style={{
            backgroundImage: 'url(' + logo + ')',
            backgroundRepeat: 'no-repeat',
            width: 'auto',
            height: '140px',
          }}
        >
          <Col xs='auto'>        
            <h1 
              className='display-3 lh-1 text-white'
              style={{
                fontWeight: '100',
                fontSize: '53px'
              }}
            >
              BW Nuclear | {siteString}
            </h1>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Hero.propTypes = {
  siteTitle: PropTypes.string
}

Hero.defaultProps = {
  siteTitle: ''
}

export default Hero
