import React, { useEffect, useRef } from 'react'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

const LoadingAlert = (props) => {
  return (
    <Alert>
      {props.text}
      {' '}
      <Spinner animation='border' size='sm'/>
    </Alert>
  )
}

export default LoadingAlert