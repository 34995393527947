import React from 'react'
import Alert from 'react-bootstrap/Alert'
import ProgressBar from 'react-bootstrap/ProgressBar'

const DownloadProgressAlert = (props) => {
  return (
    <Alert>
      Downloading file...
      <ProgressBar now={props.progress} />
    </Alert>
  )
}

export default DownloadProgressAlert