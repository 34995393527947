import React from 'react'
import Alert from 'react-bootstrap/Alert'

const url = (
  'https://demo-auth.auth-fips.us-gov-west-1.amazoncognito.com/login?' + 
  'client_id=2h29amcnc9m9e022gn5b3v7d7k&response_type=token&scope=' +
  'openid+profile&redirect_uri=https://api.demo.bwnuclear.ai/setcookie/'
)

const RenewAuthAlert = (props) => {
  const handleAlertClose = props.remove
  return (
    <Alert dismissible onClose={handleAlertClose}>
      Authorization is failing, sign in&nbsp;
      <Alert.Link href={url}>
        here
      </Alert.Link>
      .
    </Alert>
  )
}

export default RenewAuthAlert
